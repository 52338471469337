import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { GPWebPay } from '@sga/shopify-payments-types'

import {
  Banner,
  Button,
  Card,
  Form,
  FormLayout,
  Heading,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris'

import { createApi } from '../api'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import { FormTextField } from './FormTextField'

type TProps = {
  onSuccess(): void
}

GPWebPayCredentialsForm.segment = segment('gpwebpay')

export function GPWebPayCredentialsForm({ onSuccess }: TProps) {
  const config = useAuth()
  const {
    segmentCredentials,
    segmentConfig: { testCredentials },
  } = config.account as GPWebPay.TSegmentAccount
  const form = useForm<GPWebPay.TSegmentCredentials>({
    reValidateMode: 'onBlur',
  })

  const [testMode, setTestMode] = React.useState(true)
  const [status, setStatus] = React.useState<'init' | 'failed' | 'done'>('init')

  React.useEffect(() => {
    if (testMode) {
      form.reset({
        merchantNumber: testCredentials?.merchantNumber,
        privateKey: testCredentials?.privateKey,
        privateKeyPass: testCredentials?.privateKeyPass,
        providerCode: testCredentials?.providerCode,
      })
    } else {
      form.reset({
        merchantNumber: segmentCredentials.merchantNumber,
        privateKey: segmentCredentials.privateKey,
        privateKeyPass: segmentCredentials.privateKeyPass,
        providerCode: segmentCredentials.providerCode,
      })
    }
  }, [testMode, form, segmentCredentials, testCredentials])

  const onSubmit = form.handleSubmit(async (values) => {
    setStatus('init')
    const api = createApi()
    const { success } = await api.verifyCredentials(values, testMode)
    if (success) {
      setStatus('done')
      if (testMode) {
        setTestMode(false)
      } else {
        onSuccess()
      }
    } else {
      setStatus('failed')
    }
  })

  return (
    <Card sectioned title={getTranslation('Údaje k propojení s GPWebPay', 'Credentials for connecting with GPWebPay')}>
      <Stack vertical>
        <TextContainer>
          <p>
            {getTranslation(
              'Máte již založený účet GPWebPay? Potřebujeme vaše přihlašovací údaje pro komunikaci s platební bránou.',
              "Already have a GPWebPay account set up? We need your credentials for communication with the payment gateway.",
            )}
          </p>
          <p>
            <TextStyle variation="strong">
              {testMode
                ? getTranslation(
                    'Nejprve zadejte údaje k provádění TESTovacích plateb.',
                    'Input credentials for making TEST payments first.',
                  )
                : getTranslation(
                    'Nyní vyplňte údaje pro provádění skutečných plateb. Tyto se liší od údajů pro testovací prostředí!',
                    'Now fill in the credentials for making production payments. These differ from the credentials you entered before!',
                  )}
            </TextStyle>
          </p>
        </TextContainer>
        <FormProvider {...form}>
          <Form onSubmit={onSubmit}>
            <FormLayout>
              <FormTextField name="merchantNumber" label="Merchant number" validationPattern={/^[0-9]+$/} />
              <FormTextField name="privateKey" label="Private key" />
              <FormTextField name="privateKeyPass" label="Private keypass" />
              <FormTextField name="providerCode" label="Provider code" validationPattern={/^[0-9]+$/} />
              <Stack distribution="trailing">
                <Button primary submit disabled={form.formState.isSubmitting}>
                  {getTranslation(
                    `Uložit ${testMode ? 'TESTovací' : 'produkční'} údaje`,
                    `Save ${testMode ? 'test' : 'production'} credentials`,
                  )}
                </Button>
              </Stack>
              {status === 'failed' && (
                <Banner status="critical">
                  <Heading>
                    {getTranslation('Ověření zadaných údajů selhalo.', 'Credentials verification failed.')}
                  </Heading>
                  <p>
                    {getTranslation(
                      `Ujistěte se, že nepoužívate testovací údaje pro produkční prostředí a naopak. Pozor také na nadbytečné mezery nebo chybějící znaky pokud hodnoty kopírujete z emailu.`,
                      `Make sure you do not use test credentials for a production environment and vice versa. Also pay attention to extra spaces or missing characters if you copy values from email.`,
                    )}
                  </p>
                </Banner>
              )}
            </FormLayout>
          </Form>
        </FormProvider>
        {/* <TextContainer>
          <Heading>{getTranslation('GoPay účet ještě nemáte?', "Don't have a GoPay account yet?")}</Heading>
          <p>
            {getTranslation(
              <>
                Je potřeba{' '}
                <Link url={registrationFormUrl} external>
                  vyplnit formulář společnosti GoPay
                </Link>{' '}
                a jakmile budete mít potřebné údaje, použijte stejný odkaz, kterým jste se dostali na tuto stránku.
              </>,
              <>
                You have to{' '}
                <Link url={registrationFormUrl} external>
                  fill in the GoPay form
                </Link>{' '}
                and as soon as you have all the information required, use the same link which has lead you to this page.
              </>,
            )}
          </p>
        </TextContainer> */}
      </Stack>
    </Card>
  )
}
