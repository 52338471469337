import React from 'react'
import { SegmentKey } from '@sga/shopify-payments-types'

type TSegmentSpecific = { segment: SegmentKey }

export function createSegmentPicker(segment: SegmentKey) {
  return function pickBySegment<T extends TSegmentSpecific>(pickFrom: T[], props = {}): React.FunctionComponent {
    const Component = pickFrom.find((item) => item.segment === segment)

    if (!Component) return () => null

    // TODO - no ts-ignore, type correctly
    // @ts-ignore
    return () => <Component {...props} />
  }
}

export function segment(segmentKey: SegmentKey): SegmentKey {
  return segmentKey
}
