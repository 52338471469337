import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { GoPay } from '@sga/shopify-payments-types'

import {
  Banner,
  Button,
  Card,
  Form,
  FormLayout,
  Heading,
  Link,
  Stack,
  TextContainer,
  TextStyle,
} from '@shopify/polaris'

import { createApi } from '../api'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import { FormTextField } from './FormTextField'

type TProps = {
  onSuccess(): void
}

const registrationFormUrl = 'https://account.gopay.com/dodavatele/ents?lang=cs'

GoPayCredentialsForm.segment = segment('gopay')

export function GoPayCredentialsForm({ onSuccess }: TProps) {
  const config = useAuth()
  const {
    segmentCredentials,
    segmentConfig: { testCredentials },
  } = config.account as GoPay.TSegmentAccount
  const form = useForm<GoPay.TSegmentCredentials>({
    reValidateMode: 'onBlur',
  })

  const [testMode, setTestMode] = React.useState(true)
  const [status, setStatus] = React.useState<'init' | 'failed' | 'done'>('init')

  React.useEffect(() => {
    if (testMode) {
      form.reset({
        clientId: testCredentials?.clientId,
        clientSecret: testCredentials?.clientSecret,
        goId: testCredentials?.goId,
      })
    } else {
      form.reset({
        clientId: segmentCredentials.clientId,
        clientSecret: segmentCredentials.clientSecret,
        goId: segmentCredentials.goId,
      })
    }
  }, [testMode, form, segmentCredentials, testCredentials])

  const onSubmit = form.handleSubmit(async (values) => {
    setStatus('init')
    const api = createApi()
    const { success } = await api.verifyCredentials(values, testMode)
    if (success) {
      setStatus('done')
      if (testMode) {
        setTestMode(false)
      } else {
        onSuccess()
      }
    } else {
      setStatus('failed')
    }
  })

  const onSynchCredentials = async () => {
    const api = createApi()
    api.synchCredentials().then(({account}) => {
      if (account) {
        config.account.segmentCredentials = account.segmentCredentials;
        config.account.segmentConfig.testCredentials = account.segmentConfig.testCredentials;
        form.reset(testMode ? account.segmentConfig.testCredentials : account.segmentCredentials);
        console.log('Updated account:', config.account);
      }
    })
  }

  return (
    <Stack vertical>
      <Card sectioned title={getTranslation('Je třeba synchronizovat přihlašovací údaje?', 'Need to synchronize credentials?')}>
        <Stack distribution="equalSpacing">
          <TextContainer>
            {getTranslation('Chcete-li synchronizovat své přihlašovací údaje, klikněte sem', 'To synchronize your credentials click here')}
          </TextContainer>
          <Button primary submit onClick={onSynchCredentials}>
            {getTranslation('Synchronizovat', 'Synchronize')}
          </Button>
        </Stack>
      </Card>
      <Card sectioned title={getTranslation('Údaje k propojení s GoPay', 'Credentials for connecting with GoPay')}>
        <Stack vertical>
          <TextContainer>
            <p>
              {getTranslation(
                'Máte již GoPay účet zřízený? Potřebujeme od Vás údaje pro komunikaci s platební bránou. Tyto jste obdrželi od společnosti GoPay pravděpodobně formou e-mailové zprávy. Pečlivě je opište do tohoto formuláře.',
                "Already have a GoPay account set up? We need your credentials for communication with the payment gateway. You've likely received those from GoPay by email.",
              )}
            </p>
            <p>
              <TextStyle variation="strong">
                {testMode
                  ? getTranslation(
                      'Nejprve zadejte údaje k provádění TESTovacích plateb.',
                      'Input credentials for making TEST payments first.',
                    )
                  : getTranslation(
                      'Nyní vyplňte údaje pro provádění skutečných plateb. Tyto se liší od údajů pro testovací prostředí!',
                      'Now fill in the credentials for making production payments. These differ from the credentials you entered before!',
                    )}
              </TextStyle>
            </p>
          </TextContainer>
          <FormProvider {...form}>
            <Form onSubmit={onSubmit}>
              <FormLayout>
                <FormTextField name="clientId" label="Client ID" />
                <FormTextField name="clientSecret" label="Client Secret" />
                <FormTextField name="goId" label="GoID" validationPattern={/^[0-9]{10}$/} />
                <Stack distribution="trailing">
                  <Button primary submit disabled={form.formState.isSubmitting}>
                    {getTranslation(
                      `Ověřit ${testMode ? 'TESTovací' : 'produkční'} údaje`,
                      `Verify ${testMode ? 'test' : 'production'} credentials`,
                    )}
                  </Button>
                </Stack>
                {status === 'failed' && (
                  <Banner status="critical">
                    <Heading>
                      {getTranslation('Ověření zadaných údajů selhalo.', 'Credentials verification failed.')}
                    </Heading>
                    <p>
                      {getTranslation(
                        `Ujistěte se, že nepoužíváte testovací údaje pro produkční prostředí a naopak. Pozor také na nadbytečné mezery nebo chybějící znaky, pokud hodnoty kopírujete z e-mailu.`,
                        `Make sure you do not use test credentials for a production environment and vice versa. Also pay attention to extra spaces or missing characters if you copy values from email.`,
                      )}
                    </p>
                  </Banner>
                )}
              </FormLayout>
            </Form>
          </FormProvider>
          <TextContainer>
            <Heading>{getTranslation('GoPay účet ještě nemáte?', "Don't have a GoPay account yet?")}</Heading>
            <p>
              {getTranslation(
                <>
                  Je potřeba{' '}
                  <Link url={registrationFormUrl} external>
                    vyplnit formulář společnosti GoPay
                  </Link>{' '}
                  a jakmile budete mít potřebné údaje, použijte stejný odkaz, kterým jste se dostali na tuto stránku.
                </>,
                <>
                  You have to{' '}
                  <Link url={registrationFormUrl} external>
                    fill in the GoPay form
                  </Link>{' '}
                  and as soon as you have all the required information, use the same link which has led you to this page.
                </>,
              )}
            </p>
          </TextContainer>
        </Stack>
      </Card>
    </Stack>
  )
}
