import { FormProvider } from 'react-hook-form'

import { Button, Card, Collapsible, Form, SelectOption, Stack, TextContainer, Toast } from '@shopify/polaris'

import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { segment } from '../util/createSegmentPicker'
import { getTranslation } from '../util/getTranslation'
import { GoPayDefaultInstrument } from './GoPayDefaultInstrument'
// import { SettingsContactCard } from './SettingsContactCard'
import { SettingsLanguage } from './SettingsLanguage'
import { SettingsPaymentLabel } from './SettingsPaymentLabel'
import { SettingsNumericId } from './SettingsNumericId'
import { useEffect, useState } from 'react'
import { TAccountActivationStatus } from '@sga/shopify-payments-types'
import { useAuth } from './AuthContext'
import { createApi } from '../api'

type TProps = {
  goBack: () => any
  status: TAccountActivationStatus
}
GoPayAccountSettings.segment = segment('gopay')

export function GoPayAccountSettings({ goBack, status }: TProps) {
  const { appType } = useAuth()
  const [advancedCollapsibleOpen, setAdvancedCollapsibleOpen] = useState(false)
  const [appStatus, setAppStatus] = useState({ cardPayment: false, bankPayment: false })

  const { form, saveStatus, setSaveStatus, onSubmit } = useAccountSettingsForm((api, formValues) =>
    api.saveConfig(formValues),
  )

  useEffect(() => {
    if (status !== 'new' && !appType) {
      const api = createApi()
      api.appStatus().then(({appStatus}) => {
        setAppStatus(appStatus);
      })
    }
  }, [status, appType])

  if (status === 'new') {
    // Don't bother user with too much stuff initially
    return null
  }

  return (
    <Card title={getTranslation('Nastavení brány', 'Gateway settings')}>
      <FormProvider {...form}>
        <Form onSubmit={onSubmit}>
          {/* Removing currently no need for this */}
          {/* <SettingsContactCard /> */}
          <GoPayDefaultInstrument appStatus={appStatus}/>
          <SettingsLanguage languages={getLanguages()} />
          <SettingsPaymentLabel />
          <Card.Section title={getTranslation('Pokročilé API nastavení', 'Advanced API settings')}>
            <TextContainer>
              <b>
                {getTranslation(
                  'Toto nastavení je určeno pouze pro pokročilé uživatele!',
                  'These settings should be changed only by advanced users!',
                )}
              </b>
            </TextContainer>
            <br />

            <Button onClick={() => setAdvancedCollapsibleOpen(!advancedCollapsibleOpen)}>
              {advancedCollapsibleOpen ? getTranslation('Skrýt', 'Hide') : getTranslation('Zobrazit', 'Display')}
            </Button>

            <Collapsible id="advanced-settings-collapsable" open={advancedCollapsibleOpen}>
              <br />
              <SettingsNumericId />
            </Collapsible>
          </Card.Section>
          <Card.Section>
            <Stack distribution="equalSpacing">
              <Button onClick={goBack}>{getTranslation('Změnit údaje', 'Change Credentials')}</Button>
              <Button primary submit disabled={form.formState.isSubmitting}>
                {getTranslation('Uložit nastavení', 'Save settings')}
              </Button>
            </Stack>
          </Card.Section>
        </Form>
      </FormProvider>
      {saveStatus === 'success' && (
        <Toast
          content={getTranslation('Nastavení uloženo', 'Settings saved')}
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
      {saveStatus === 'failure' && (
        <Toast
          content={getTranslation('Ukládání selhalo', 'Saving failed')}
          error
          onDismiss={() => setSaveStatus('submit')}
        />
      )}
    </Card>
  )
}

function getLanguages(): SelectOption[] {
  return [
    { value: '' as any, label: getTranslation('Automaticky', 'Automatically') },
    { value: 'CS', label: getTranslation('Čeština', 'Czech') },
    { value: 'EN', label: getTranslation('Angličtina', 'English') },
    { value: 'SK', label: getTranslation('Slovenština', 'Slovak') },
    { value: 'DE', label: getTranslation('Němčina', 'German') },
    { value: 'RU', label: getTranslation('Ruština', 'Russian') },
    { value: 'PL', label: getTranslation('Polština', 'Polish') },
    { value: 'HU', label: getTranslation('Maďarština', 'Hungarian') },
    { value: 'FR', label: getTranslation('Francouzština', 'French') },
    { value: 'RO', label: getTranslation('Rumunština', 'Romanian') },
    { value: 'BG', label: getTranslation('Bulharština', 'Bulgarian') },
    { value: 'HR', label: getTranslation('Chorvatština', 'Croatian') },
    { value: 'IT', label: getTranslation('Italština', 'Italian') },
    { value: 'ES', label: getTranslation('Španělština', 'Spanish') },
    { value: 'UK', label: getTranslation('Ukrajinština', 'Ukrainian') },
    { value: 'ET', label: getTranslation('Estonština', 'Estonian') },
    { value: 'LT', label: getTranslation('Litevština', 'Lithuanian') },
    { value: 'LV', label: getTranslation('Lotyština', 'Latvian') },
    { value: 'SL', label: getTranslation('Slovinština', 'Slovenian') },
    { value: 'PT', label: getTranslation('Portugalština', 'Portuguese') }
]
}
