import { ReactNode } from 'react'

import { useController, useFormContext } from 'react-hook-form'
import { GoPay } from '@sga/shopify-payments-types'

import { Card, ChoiceList, Link, Banner, Stack, TextContainer, Button } from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { useAuth } from './AuthContext'
import { getTranslation } from '../util/getTranslation'

type TAppStatus = { cardPayment: boolean, bankPayment: boolean }
interface GoPayDefaultInstrumentProps {
  appStatus: TAppStatus;
}

function handleOpenApp (appLink: string) {
  window.open(appLink, '_blank', 'noopener,noreferrer');
};

export function GoPayDefaultInstrument({ appStatus }: GoPayDefaultInstrumentProps) {
  const account = useAccount<GoPay.TSegmentAccount>()
  const { appType } = useAuth()
  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'defaultPaymentInstrument',
    defaultValue: account.segmentConfig.defaultPaymentInstrument || '',
  })

  function GoPayDefaultView () {
    return (
      <>
        <Banner status="info">
          {getTranslation(
            'Chcete-li zákazníkovi umožnit vybrat si v pokladně mezi platbou kartou a bankovním převodem, postupujte podle ',
            'To let customers choose between card and bank transfer at checkout, follow these '
          )}
          <Link  external url={`https://help.gopay.com/${getTranslation('cs/s/meY', 'en/s/me2')}`}>
            {getTranslation(
              'návodu',
              'instructions'
            )}
          </Link>.
        </Banner>
        <br/>
        <ChoiceList
          selected={[field.value]}
          title={getTranslation('Preferovaná platební metoda', 'Preferred payment method')}
          choices={getInstruments(appStatus, field.value).map(({ label, value, help }) => ({
            label,
            value,
            helpText: help,
          }))}
          onChange={(selected) =>
            setValue(field.name, selected[0], {
              shouldDirty: true,
              shouldTouch: true,
            })
          }
        />
      </>
    )
  }

  function GoPayAppView ({ appAInfo, appBInfo}: { appAInfo: PaymentAppInfo, appBInfo: PaymentAppInfo }) {
    return (
      <>
        <TextContainer>
          GoPay Payment Options
        </TextContainer>
        <br />
        <AppSectionView appInfo={appAInfo}/>
        <br />
        <AppSectionView appInfo={appBInfo}/>
      </>
    ) 
  }

  function AppSectionView ({ appInfo }: { appInfo: PaymentAppInfo }) {
    return (
      <Stack distribution="equalSpacing" alignment='center'>
        <TextContainer>
          {appInfo.name}
          <p>
            <Link external url={`https://www.gopay.com/${appInfo.helpPath}`}>
              {getTranslation('Další informace', 'More information')}
            </Link>
          </p>
        </TextContainer>
        <Button onClick={() => handleOpenApp(appInfo.appLink)}>{getTranslation('OTEVŘENO', 'Open')}</Button>
      </Stack>
    )
  }

  function selectView () {
    switch (appType) {
      case 'PAYMENT_CARD':
        return <GoPayAppView appAInfo={appInfo().gopay} appBInfo={appInfo().gopayBank}/>
      case 'BANK_ACCOUNT':
        return <GoPayAppView appAInfo={appInfo().gopay} appBInfo={appInfo().gopayCard}/>
      default:
        return <GoPayDefaultView />
    }
  }

  return (
    <Card.Section>
      {selectView()}
    </Card.Section>
  )
}

function appInfo() {
  return {
    gopay: {
      name: getTranslation('Platby GoPay', 'GoPay payments'),
      appLink: 'https://google.com',
      helpPath: getTranslation('cs', 'en')
    },
    gopayCard: {
      name: getTranslation('Platby kartou GoPay', 'GoPay card payments'),
      appLink: 'https://google.com',
      helpPath: getTranslation('cs/online-platba-kartou', 'en/online-card-payments')
    },
    gopayBank: {
      name: getTranslation('Bankovní platby GoPay', 'GoPay bank payments'),
      appLink: 'https://google.com',
      helpPath: getTranslation('cs/online-bankovni-prevod', 'en/online-bank-tranfer')
    }
  }
}

type PaymentAppInfo = {
  name: string;
  appLink: string;
  helpPath: string;
}

type TInstrument = {
  value: GoPay.TPaymentInstrument
  label: string
  helpPath?: string
  help?: ReactNode
}

function getInstruments(appStatus: TAppStatus, type: string): ReadonlyArray<TInstrument> {
  const installedBanner = (
    <Banner status="warning">
      {getTranslation(
        'V pokladně je již aktivní doplněk umožňující tuto platební metodu. Zvolením této možnosti vytvoříte duplicitní možnost pro platbu. Doporučujeme ji nevybírat.',
        `There's already an add-on for this payment method in the checkout. Selecting this option will create a duplicate payment choice. We recommend not selecting it.`
      )}
    </Banner>
  );

  const createHelpContent = (url: string) => (
    <Stack distribution="equalSpacing" alignment='center'>
      <TextContainer>
        <p>
          <Link external url={url}>
            {getTranslation('Další informace', 'More information')}
          </Link>
        </p>
      </TextContainer>
      <Button onClick={() => handleOpenApp("https://google.com")}>{getTranslation('OTEVŘENO', 'Open')}</Button>
    </Stack>
  );

  return [
    {
      value: '' as any,
      label: getTranslation('Zákazník si vybere', 'Customer will choose'),
      help: (
        <span>
          {getTranslation(
            `Můžete vybrat platební metodu, kterou chcete, aby zákazník využíval nejčastěji.
             I přes toto nastavení si však zákazník může vybrat jinou (z vámi povolených) možností.`,
            `You can choose payment method that you want the customer used it most often.
            However, despite this setting, the customer can choose another option (from the ones you allow)
            `,
          )}
        </span>
      ),
    },
    {
      value: 'PAYMENT_CARD',
      label: getTranslation('Platba kartou', 'Card payment'),
      help: appStatus.cardPayment && type === 'PAYMENT_CARD' ? 
        installedBanner : 
        createHelpContent(`https://www.gopay.com/${getTranslation('cs/online-platba-kartou', 'en/online-card-payments')}`)
    },
    {
      value: 'BANK_ACCOUNT',
      label: getTranslation('Bankovní převod', 'Bank transfer'),
      help: appStatus.bankPayment && type === 'BANK_ACCOUNT' ? 
        installedBanner : 
        createHelpContent(`https://www.gopay.com/${getTranslation('cs/online-bankovni-prevod', 'en/online-bank-tranfer')}`)
    }
  ]
};