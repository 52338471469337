import React from 'react'

import { useController, useFormContext } from 'react-hook-form'

import { TextField, TextFieldProps } from '@shopify/polaris'

import { getTranslation } from '../util/getTranslation'

type TProps = Omit<TextFieldProps, 'autoComplete'> & {
  name: string
  label: string
  validationPattern?: RegExp
  autoComplete?: string
  required?: boolean
}

export function FormTextField({
  name,
  label,
  validationPattern,
  autoComplete = '',
  required = true,
  ...fieldProps
}: TProps) {
  const { setValue, getFieldState, control, formState } = useFormContext()
  const { field } = useController({
    name,
    rules: {
      required: required && getTranslation('Vyplňte údaj', 'Enter the value'),
      pattern: validationPattern
        ? {
            message: getTranslation(
              'Ujistěte se, že zadaná hodnota je správně.',
              'Make sure that entered value is correct.',
            ),
            value: validationPattern,
          }
        : undefined,
    },
    control,
  })

  return (
    <TextField
      {...field}
      {...fieldProps}
      onChange={(value) => {
        if (field.value !== value) {
          setValue(name, value, { shouldDirty: true, shouldValidate: true })
        }
      }}
      label={label}
      autoComplete={autoComplete}
      error={getFieldState(name).error?.message}
      disabled={formState.isSubmitting}
    />
  )
}
