import React from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import { ComGate } from '@sga/shopify-payments-types'

import { Banner, Button, Card, Form, FormLayout, Heading, Stack, TextContainer } from '@shopify/polaris'

import { createApi } from '../api'
import { getTranslation } from '../util/getTranslation'
import { useAuth } from './AuthContext'
import ComGateCredentialsGuide from './ComGateCredentialsGuide'
import { FormTextField } from './FormTextField'

type TProps = {
  onSuccess(): void
}

export function ComGateCredentialsForm({ onSuccess }: TProps) {
  const config = useAuth()
  const { segmentCredentials } = config.account as ComGate.TSegmentAccount
  const form = useForm<ComGate.TSegmentCredentials>({
    reValidateMode: 'onBlur',
    defaultValues: {
      merchant: segmentCredentials.merchant,
      secret: segmentCredentials.secret,
    },
  })

  const [status, setStatus] = React.useState<'init' | 'failed' | 'done'>('init')

  const onSubmit = form.handleSubmit(async (values) => {
    setStatus('init')
    const api = createApi()
    const { success } = await api.verifyCredentials(values)
    if (success) {
      setStatus('done')
      onSuccess()
    } else {
      setStatus('failed')
    }
  })

  return (
    <Card sectioned title={getTranslation('Údaje k propojení s ComGate', 'Details for connecting with ComGate')}>
      <Stack vertical>
        <TextContainer>
          <p>
            {getTranslation(
              'Jakmile budete mít údaje pro propojení s ComGate, vyplňte je do formuláře níže pro dokončení aktivace.',
              'Once you have the credentials to connect with ComGate, fill it out in the form below to complete the activation.',
            )}
          </p>
        </TextContainer>
        <ComGateCredentialsGuide />
        <FormProvider {...form}>
          <Form onSubmit={onSubmit}>
            <FormLayout>
              <FormTextField
                name="merchant"
                label={getTranslation('Identifikátor propojení obchodu', 'Shop connection identifier')}
              />
              <FormTextField name="secret" label={getTranslation('Heslo', 'Password')} />
              <Stack distribution="trailing">
                <Button primary submit disabled={form.formState.isSubmitting}>
                  {getTranslation('Ověřit údaje', 'Verify details')}
                </Button>
              </Stack>
              {status === 'failed' && (
                <Banner status="critical">
                  <Heading>
                    {getTranslation('Ověření zadaných údajů selhalo.', 'Details verification went wrong.')}
                  </Heading>
                  <p>
                    {getTranslation(
                      <>
                        Pokud jste si jisti zadanými údaji, bude potřeba kontaktovat ComGate a pokusit se vyřešit
                        problém s nimi.
                      </>,
                      <>
                        If you're sure the details are correct, please contact ComGate and try to solve a problem with
                        them.
                      </>,
                    )}
                  </p>
                </Banner>
              )}
            </FormLayout>
          </Form>
        </FormProvider>
      </Stack>
    </Card>
  )
}
