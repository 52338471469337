import { SettingToggle } from '@shopify/polaris'
import React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { useAccount } from '../hooks/useAccount'
import { getTranslation } from '../util/getTranslation'

export const SettingsNumericId = () => {
  const account = useAccount()
  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'useNumericalId',
    defaultValue: account.segmentConfig.useNumericalId ?? false,
  })

  const contentStatus = field.value ? getTranslation('Vypnout', 'Turn off') : getTranslation('Zapnout', 'Turn on')

  return (
    <SettingToggle
      enabled={field.value}
      action={{
        content: contentStatus,
        onAction: () => setValue(field.name, !field.value),
      }}
    >
      <span>{getTranslation('Využívat číslené ID pro platby', 'Use numerical ID for payments')}</span>
    </SettingToggle>
  )
}

export default SettingsNumericId
