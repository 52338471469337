import getUserLocale from 'get-user-locale'

export function getTranslation<T>(cs: T, en: T): T {
  const locale = getUserLocale()
  switch (locale) {
    case 'en':
    case 'en-US':
      return en
    default:
      return cs
  }
}
