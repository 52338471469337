import React from 'react'

import { DisableableAction, LoadableAction } from '@shopify/polaris'

import { createApi } from '../api'
import { getTranslation } from '../util/getTranslation'

export function useShopifyActivation() {
  const [status, setStatus] = React.useState<'pending' | 'activating' | 'success' | 'failure' | 'done'>('pending')

  const action: LoadableAction | DisableableAction = {
    content: getTranslation('Aktivovat', 'Activate'),
    disabled: status !== 'pending',
    loading: status === 'activating',
    onAction: async () => {
      setStatus('activating')
      const api = createApi()
      const { success, redirectUrl } = await api.activateAccount()
      if (success && redirectUrl) {
        setStatus('success')
        if (window.parent.location === window.location) {
          window.location.href = redirectUrl
        } else {
          window.parent.location.href = redirectUrl
        }
      } else {
        setStatus('failure')
      }
    },
  }

  const dismiss = React.useCallback(() => setStatus('done'), [])

  return { action, status, dismiss }
}
