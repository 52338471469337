import { DisableableAction } from '@shopify/polaris'

import { useAuth } from '../components/AuthContext'
import { getTranslation } from '../util/getTranslation'

export function useBackToShopAction(): DisableableAction {
  const { shopGatewayUrl } = useAuth()
  return {
    content: getTranslation('Zpět do obchodu', 'Back to the shop'),
    url: shopGatewayUrl,
  }
}
