import { Card, FormLayout } from '@shopify/polaris'

import { getTranslation } from '../util/getTranslation'
import { FormTextField } from './FormTextField'

type TProps = {
  emailRequired?: boolean
}

export function SettingsContactCard({ emailRequired = false }: TProps) {
  return (
    <Card.Section>
      <FormLayout>
        <FormTextField
          name="contactEmail"
          label={`${getTranslation('Kontaktní e-mail', 'Contact e-mail')}${emailRequired ? ' * ' : ''}`}
          helpText={
            emailRequired
              ? undefined
              : getTranslation(
                  'Pokud chcete v budoucnu být informováni o případných novinkách či změnách souvisejících s platbami na Shopify, vyplňte prosím kontaktní e-mailovou adresu.',
                  'If you want to be informed about news or changes related to payments on Shopify, please fill in the contact e-mail address. ',
                )
          }
          autoComplete="email"
          required={emailRequired}
        />
        <FormTextField
          name="contactPhone"
          label={getTranslation('Kontaktní telefon', 'Contact phone')}
          helpText={getTranslation(
            'Pro rychlejší formu komunikace nám můžete sdělit telefonní číslo, kde vás můžeme zastihnout v případě potřeby.',
            'For a faster form of communication, you can tell us a phone number where we can reach you if needed.',
          )}
          autoComplete="tel"
          required={false}
        />
      </FormLayout>
    </Card.Section>
  )
}
