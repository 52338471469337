import ky from 'ky'
import { TAccount, TAppAuthPayload, TVerifyCredentialsResult } from '@sga/shopify-payments-types'

export const AUTH_TOKEN_KEY = 'token'

export function createApi() {
  const api = ky.extend({
    // we have cold start on gopay apps, so we need this changing it from default 10s to 15s
    timeout: 15000,
    prefixUrl: '/app',
    mode: 'cors',
    hooks: {
      beforeRequest: [
        (request) => {
          const token = window.sessionStorage.getItem(AUTH_TOKEN_KEY) || null
          if (token) {
            request.headers.set('x-token', token)
          }
        },
      ],
    },
  })

  return {
    async authenticate() {
      return api.get(`auth${window.location.search}`).json<TAppAuthPayload & { appType: string | null }>()
    },
    readAccount() {
      return api.get('account').json<{ account: TAccount | null }>()
    },
    async appStatus() {
      return await api.get('appStatus').json<{ appStatus: { cardPayment: boolean, bankPayment: boolean }}>()
    },
    async synchCredentials() {
      return await api.get('synchCredentials').json<{ account: TAccount | null}>()
    },
    verifyCredentials(credentials: Dictionary, testMode: boolean = false) {
      return api
        .post('verifyCredentials', {
          json: { credentials, isTest: testMode },
        })
        .json<TVerifyCredentialsResult>()
    },
    async saveConfig(config: Dictionary) {
      await api.post('saveConfig', { json: config })
    },
    async registerComGate(config: Dictionary) {
      await api.post('registerComGate', { json: config })
    },
    activateAccount() {
      return api.post('activateAccount').json<{ success: boolean; redirectUrl: string }>()
    },
  }
}

export type TApi = ReturnType<typeof createApi>
