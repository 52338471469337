import React from 'react'

import { FormProvider } from 'react-hook-form'
import { ComGate } from '@sga/shopify-payments-types'

import { ActionMenu, Banner, Button, Card, Collapsible, Form, Heading, Stack, TextContainer } from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { useAccountSettingsForm } from '../hooks/useAccountSettingsForm'
import { useBackToShopAction } from '../hooks/useBackToShopAction'
import { getTranslation } from '../util/getTranslation'
import { SettingsContactCard } from './SettingsContactCard'

type TProps = {
  onSuccess(): void
}

export function ComGateRegistration({ onSuccess }: TProps) {
  const {
    segmentConfig: { registrationSentAt },
  } = useAccount<ComGate.TSegmentAccount>()

  const { saveStatus, form, onSubmit } = useAccountSettingsForm((api, formValues) => api.registerComGate(formValues))

  React.useEffect(() => {
    if (registrationSentAt) {
      onSuccess()
    }
  }, [registrationSentAt, onSuccess])

  return (
    <Card sectioned title={getTranslation('Registrace ComGate účtu', 'Registration of ComGate account')}>
      <Stack vertical>
        <Collapsible id="new" open={saveStatus === 'submit'}>
          <TextContainer>
            <p>
              {getTranslation(
                'Na základě tohoto formuláře pošleme do ComGate emailovou zprávu obsahující údaje pro nastavení komunikace s tímto účtem. V následujících dnech Vás bude ComGate kontaktovat s dalším postupem.',
                "Based on this form we'll sent an email to ComGate with the details for setting up the communication with this account. In the next few days ComGate will be in touch with you with next steps.",
              )}
            </p>
            <p>
              {getTranslation(
                'Máte již ComGate účet zřízený? Odešlete formulář i v takovém případě a ComGate se postará o nastavení Vašeho účtu. Ujistěte se, že zadáváte stejnou emailovou adresu jako máte ve vytvořeném ComGate účtu.',
                'Do you already have a ComGate account? Submit the form even then and ComGate will take care of setting up your account. Make sure you enter the same email address as you have in the created ComGate account.',
              )}
            </p>
          </TextContainer>
          <FormProvider {...form}>
            <Form onSubmit={onSubmit}>
              <SettingsContactCard emailRequired={true} />
              <Card.Section>
                <Stack distribution="trailing">
                  <Button primary submit disabled={form.formState.isSubmitting}>
                    {getTranslation('Odeslat registraci', 'Sent registration')}
                  </Button>
                </Stack>
              </Card.Section>
            </Form>
          </FormProvider>
        </Collapsible>
        <Collapsible id="done" open={saveStatus === 'success'}>
          <Stack vertical>
            <Banner status="success">
              <Heading>{getTranslation('Registrace byla odeslána.', 'Registration was sent.')}</Heading>
            </Banner>
            <TextContainer>
              <p>
                {getTranslation(
                  'Jakmile bude Vaše komunikace s ComGate vyřešena, použijte stejný odkaz k instalaci této aplikace a vraťte se sem pro dokončení procesu.',
                  'Once your communication with ComGate is resolved, use the same link to install this application and return here to complete the process.',
                )}
              </p>
            </TextContainer>
            <ActionMenu actions={[useBackToShopAction()]} />
          </Stack>
        </Collapsible>
      </Stack>
    </Card>
  )
}
