import { useController, useFormContext } from 'react-hook-form'
import { GoPay } from '@sga/shopify-payments-types'

import { Card, Select, SelectOption } from '@shopify/polaris'

import { useAccount } from '../hooks/useAccount'
import { getTranslation } from '../util/getTranslation'

export function SettingsLanguage({ languages }: { languages: SelectOption[] }) {
  const account = useAccount<GoPay.TSegmentAccount>()
  const { setValue } = useFormContext()
  const { field } = useController({
    name: 'language',
    defaultValue: account.segmentConfig.language || '',
  })

  return (
    <Card.Section>
      <Select
        helpText={getTranslation(
          'Automaticky podle jazyka e-shopu nebo vyberte konkrétní jazyk.',
          "Automatically based on the language of the website or choose a specific language.",
        )}
        label={getTranslation('Preferovaný jazyk brány', 'Preferred gateway language')}
        value={field.value}
        options={languages}
        onChange={(selected) => {
          setValue(field.name, selected, {
            shouldDirty: true,
            shouldTouch: true,
          })
        }}
      />
    </Card.Section>
  )
}
